import React from 'react'
import '../../App.css'
import SkillSection from '../SkillSection'

function Skills() {
    return (
        <>
            <SkillSection />
        </>
    )
}

export default Skills;