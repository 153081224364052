import React from "react";
import VirtualBoxSection from "../VirtualBoxSection";

const VirtualBox = () => {
  return (
    <>
      <VirtualBoxSection />
    </>
  );
};

export default VirtualBox;
