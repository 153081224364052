import React from 'react'
import ContactSection from '../Contact/ContactSection'

function Contact() {
    return (
        <>
            <ContactSection />
        </>
    )
}

export default Contact
